import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import Navibar from './cmp/Navibar'
import Login from './pages/Login';
import Home from './pages/Home';
import MyProfiles from './pages/MyProfiles';
import Results from './pages/Results';
import Paypal from './pages/Paypal';
import Register from './pages/Register'
import Forgotpassword from './pages/Forgotpassword'
import SetPassword from './pages/SetPassword'
import RegisterByRef from './pages/RegisterByRef'
import Clients from "./pages/Team";
import SipAccounts from "./pages/SipAccounts";
import FAQ from "./pages/Faq";

export const UserContext = React.createContext(null);

const ProtectedRoute = ({ user, redirectPath = '/', children, }) => {
  const userData = sessionStorage.getItem('user');
  if (userData) {
    const parsedData = JSON.parse(userData);
    
    if (parsedData.expirationTime && parsedData.expirationTime > new Date().getTime()) {
      user = (parsedData.token);
    } else {
      // Clear expired user data from sessionStorage
      sessionStorage.removeItem('user');
    }
  }
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

/* const ProtectedAdminRoute = ({ user, redirectPath = '/', children, }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  else if (user.level !== 0) {
    return <Navigate to={redirectPath + "home"} replace />;
  }
  return children;
}; */

function App() {

  const [user, setUser] = React.useState(null);

  useEffect(() => {
    // Check if user data is present in sessionStorage
    const userData = sessionStorage.getItem('user');

    if (userData) {
      const parsedData = JSON.parse(userData);
      console.log(parsedData)
      // Check if user data is not expired
      if (parsedData.expirationTime && parsedData.expirationTime > new Date().getTime()) {
        setUser(parsedData.user);
      } else {
        // Clear expired user data from sessionStorage
        sessionStorage.removeItem('user');
      }
    }
  }, []);

  return (
    <>
      <UserContext.Provider value={{ user: user, setUser: setUser }}>
        <Navibar setUser={setUser} user={user} />
        <Routes>
          <Route path="/" element={<Login setUser={setUser} user={user} />} />
          <Route path="/register" element={<Register setUser={setUser} user={user} />} />
          <Route path="/forgotpassword" element={<Forgotpassword setUser={setUser} user={user} />} />
          <Route path="/setpassword" element={<SetPassword setUser={setUser} user={user} />} />
          <Route path="/register/ref/:parentID" element={<RegisterByRef setUser={setUser} user={user} />} />
          <Route path="/home" element={<ProtectedRoute user={user}><Home /></ProtectedRoute>} />
          <Route path="/myprofiles" element={<ProtectedRoute user={user}><MyProfiles /></ProtectedRoute>} />
          <Route path="/clients" element={<ProtectedRoute user={user}><Clients /></ProtectedRoute>} />
          <Route path="/sipaccounts" element={<ProtectedRoute user={user}><SipAccounts /></ProtectedRoute>} />
          <Route path="/results" element={<ProtectedRoute user={user}><Results /></ProtectedRoute>} />
          <Route path="/paypal" element={<ProtectedRoute user={user}><Paypal /></ProtectedRoute>} />
          <Route path="/faq" element={<ProtectedRoute user={user}><FAQ /></ProtectedRoute>} />
        </Routes>
      </UserContext.Provider>      
    </>
  );
}

export default App;
