import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from '../axios';
import ErrorModal from "../cmp/ErrorModal"
import { Grid, Paper } from "@mui/material";

function RegisterByRef() {
    const params = useParams();
    const [email, setEmail] = React.useState('');
    const [emailInvalid, setEmailInvalid] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState();
    const navigate = useNavigate();

    const handleRegister = () => {
        const loginPayload = {
            password: password,
            email: email,
            parentID: params.parentID,
            profile: {}
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setEmailInvalid(true)
            return
        }
        else
            setEmailInvalid(false)


        axios.post("/user", loginPayload)
            .then(response => {
                if (response.data.status === "error") {
                    setMessage(response.data.msg)
                    setOpen(true)
                }
                else {
                    setMessage("Neuer Benutzer wurde erzeugt!")
                    setOpen(true)
                    setTimeout(() => { navigate('/') }, 3000)
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>                
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <h1>Registrierung</h1>
                    <form onSubmit={handleRegister}>
                        <Grid container width={400} spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email_required"
                                    label="E-Mail"
                                    onInput={e => setEmail(e.target.value)}
                                    hidden
                                    error={emailInvalid}
                                    helperText={emailInvalid && "Invalid format!"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Passwort"
                                    type="password"
                                    onInput={e => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6} textAlign={'center'}>
                                <Button variant="outlined" onClick={() => {                                   
                                    handleRegister(email, password)
                                }}>
                                    Registrieren
                                </Button>                                
                            </Grid>
                            <Grid item xs={6} textAlign={'center'}>               
                                <Button type="reset" variant="outlined">
                                    Zurücksetzen
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>            
            <ErrorModal open={open} setOpen={setOpen} message={message} />
        </>
    );
}

export default RegisterByRef;