import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import axios from '../axios';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function DeleteModal({ open, setOpen, message, publicID , onRefresh }) {
  const handleClose = () => { 
      setOpen(false);
  }
  const handleDelete = (publicID) => {
    axios.delete(`/user/sip/${publicID}`)
      .then(response => {
        if (response.data.status === "ok") {
          onRefresh();
          setOpen(false);
        }
        
  })
  .catch(err => console.log(err));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6">Löschen?</Typography>
        <Typography variant="body1" style={{ marginTop: '15px'}}>{message}</Typography>
        <Button variant="contained" style={{ marginRight: '30px', marginTop: '15px'}} color={'error'} onClick={() => handleDelete(publicID)}>
          Löschen
        </Button>
        <Button variant="contained" onClick={handleClose} style={{ marginTop: '15px'}}>
          Schließen
        </Button>
      </Box>
    </Modal>
  );
}

export default DeleteModal;