import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SpeedIcon from '@mui/icons-material/Speed';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../img/logo.png';
import { AccountBox, DialerSip, BugReport, Info, Support, Download } from '@mui/icons-material';
import SupportModal from './Support';

export default function Navbar(props) {

    const drawerWidth = 200;
    const [open, setOpen] = React.useState(false);

    const handleDownload = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/download`;
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
            >
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        bgcolor: '#EFF4F5'
                    },

                }}
                variant="permanent"
                anchor="left"
            >
                <Box
                    component="img"
                    alt="DIAL-ouge"
                    src={logo}
                    sx={{
                        bgcolor: 'rgba(70,102,220,255)'
                    }}
                />
                <Divider />
                {props.user !== null &&
                    <List>
                        <ListItem key="testresults" disablePadding>
                            <ListItemButton component={NavLink} to="/results">
                                <ListItemIcon>
                                    <SpeedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Testergebnisse" />
                            </ListItemButton>
                        </ListItem>
                        {/* <ListItem key="home" disablePadding>
                            <ListItemButton component={NavLink} to="/home">
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                        </ListItem> */}

                        <ListItem key="myprofiles" disablePadding>
                            <ListItemButton component={NavLink} to="/myprofiles">
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary="Profil" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem key="clients" disablePadding>
                            <ListItemButton component={NavLink} to="/clients">
                                <ListItemIcon>
                                    <AccountBox />
                                </ListItemIcon>
                                <ListItemText primary="Benutzer" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem key="sipinfo" disablePadding>
                            <ListItemButton component={NavLink} to="/sipaccounts">
                                <ListItemIcon>
                                    <DialerSip />
                                </ListItemIcon>
                                <ListItemText primary="SIP-Accounts" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                }
                <div style={{ marginTop: 'auto', marginBottom: '16px' }}>
                    {props.user !== null &&
                        <List>
                            <ListItem key="clientDownload" onClick={handleDownload} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <Download />
                                    </ListItemIcon>
                                    <ListItemText primary="Download Windows Client v1.1" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="versionInfo" disablePadding>
                                <ListItemButton component={NavLink} to="/faq">
                                    <ListItemIcon>
                                        <Info />
                                    </ListItemIcon>
                                    <ListItemText primary="FAQ" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="support" disablePadding>
                                <ListItemButton onClick={() => { setOpen(true) }} >
                                    <SupportModal open={open} setOpen={setOpen} />
                                    <ListItemIcon>
                                        <BugReport />
                                    </ListItemIcon>
                                    <ListItemText primary="Fehler melden" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key="home" disablePadding>
                                <ListItemButton component={NavLink} to="/" onClick={() => {
                                    sessionStorage.setItem("token", null);
                                    sessionStorage.removeItem('user');
                                    props.setUser(null)
                                }}>
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Ausloggen" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                </div>
            </Drawer>
        </Box >
    );
}