import React from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from '../axios';
import ErrorModal from "../cmp/ErrorModal"
import { Grid, Paper } from "@mui/material";

function Forgotpassword() {
    const [email, setEmail] = React.useState('');
    const [emailInvalid, setEmailInvalid] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState();
    const navigate = useNavigate();

    const handleForgotPassword = () => {
        const forgotPasswordPayload = {
            email: email,
            profile: {},
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setEmailInvalid(true)
            return
        }
        else
            setEmailInvalid(false)


        axios.post("/forgotpassword", forgotPasswordPayload)
            .then(response => {
                console.log(response)
                if (response.data.status === "error") {
                    setMessage(response.data.msg)
                    setOpen(true)
                }
                else {
                    setMessage("Neues Passwort festlegen")
                    setOpen(true)
                    setTimeout(() => { navigate('/') }, 3000)
                }
            })
            .catch(err => {
                setMessage(err.response.data.msg)
                setOpen(true)
                console.error("Error:", err.response.data);
                setTimeout(() => { navigate('/register') }, 3000)
              });
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>                
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <h1>Passwort zurücksetzen</h1>
                    <form onSubmit={handleForgotPassword}>
                        <Grid container width={400} spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email_required"
                                    label="E-Mail"
                                    onInput={e => setEmail(e.target.value)}
                                    hidden
                                    error={emailInvalid}
                                    helperText={emailInvalid && "Ungültiges Format!"}
                                />
                            </Grid>
                            <Grid item xs={6} textAlign={'center'}>
                                <Button variant="outlined" onClick={() => {                                   
                                    handleForgotPassword(email)
                                }}>
                                    Zurücksetzen
                                </Button>                                
                            </Grid>
                            <Grid item xs={6} textAlign={'center'}>               
                                <Button type="reset" variant="outlined" onClick={() => {                                   
                                   setTimeout(() => { navigate('/register') }, 3000)
                                }} >
                                    Registrieren
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>            
            <ErrorModal open={open} setOpen={setOpen} message={message} />
        </>
    );
}

export default Forgotpassword;