import React, { useEffect } from 'react';
import axios from '../axios';
import { Modal, Box, Typography, Button, ToggleButton, ToggleButtonGroup, TextField, FormGroup } from '@mui/material';

export default function SupportModal({ open, setOpen }) {
    const [entryID, setEntryID] = React.useState(1);
    function sendEntry() {

        const whitelistPayload = {
            [alignment]: name,
            description: description
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(name) && alignment === "email") {
            setEmailInvalid(true)
            return
        }
        else if
            (!/^[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(name) && alignment === "domain") {
            setEmailInvalid(true)
            return
        }
        else
            setEmailInvalid(false)

        axios.post("/admin/whitelist", whitelistPayload)
            .then(response => {
                if (response.data.status === "error") {
                    console.log(response.data)
                }
                handleClose()

            })
            .catch(err => console.log(err));
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 460,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [alignment, setAlignment] = React.useState('domain');
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [emailInvalid, setEmailInvalid] = React.useState(false);

    const handleClose = () => {
        console.log("closing it")
        setOpen(false)
        setName("")
        setDescription("")
        setEmailInvalid(false)
    };
    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null)
            setAlignment(newAlignment);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" style={{ marginBottom: 10 }}>Fehler melden</Typography>

                <FormGroup row>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton value="email">E-Mail</ToggleButton>
                        <ToggleButton value="domain">Domain</ToggleButton>
                    </ToggleButtonGroup>
                    <TextField
                        id="outlined-required"
                        placeholder={alignment}
                        onInput={e => setName(e.target.value)}
                        error={emailInvalid}
                        helperText={emailInvalid && "Invalid format!"}
                        style={{ marginLeft: 10 }}
                    />
                    <TextField
                        id="outlined-multiline"
                        label="Description"
                        placeholder="Description..."
                        onInput={e => setDescription(e.target.value)}
                        style={{ marginTop: 10, width: 385 }}
                    />
                </FormGroup>



                <div style={{ marginTop: 20 }}>
                    <Button variant="contained" onClick={() => sendEntry()}>
                        Add
                    </Button>
                    <Button variant="contained" onClick={handleClose} style={{ marginLeft: 10 }}>
                        Close
                    </Button>
                </div>

            </Box>
        </Modal>
    );
}