import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "../axios";
import { UserContext } from "../App";
import ErrorModal from "../cmp/ErrorModal";
import { Grid, Paper, Typography } from "@mui/material";

function Login() {
  const { setUser } = React.useContext(UserContext);

  const [email, setEmail] = React.useState("");
  const [emailInvalid, setEmailInvalid] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [passwordInvalid, setPasswordInvalid] = React.useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    const loginPayload = {
      email: email,
      password: password,
    };

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailInvalid(true);
      return;
    } else setEmailInvalid(false);

    axios
      .post("/auth/login", loginPayload)
      .then((response) => {
        if (response.data.status === "ok") {
          setUser({
            username: response.data.token,
            level: response.data.level,
          });
          sessionStorage.setItem("user", JSON.stringify({
            level: response.data.level,
            expirationTime: new Date().getTime() + (15 * 60 * 1000),
            token: response.data.token,
          }));
          const token = response.data.token;
          localStorage.setItem("token", token);
          navigate("/results");
        } else {
          setMessage("Falsche angaben");
          setOpen(true);
        }
      })
      .catch((err) => {
        setMessage("Benutzereingabe fehlerhaft");
        setOpen(true);
      });
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(enteredEmail);
    setEmailInvalid(!isValidEmail);
  };

  const handlePasswordChange = (e) => {
    const enteredPassword = e.target.value;
    setPassword(enteredPassword);
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    const isValidPassword = passwordRegex.test(enteredPassword);
    setPasswordInvalid(!isValidPassword);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Paper elevation={3} style={{ padding: "20px" }}>
          <h1>Login</h1>
          <form onSubmit={handleLogin}>
            <Grid container width={400} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email_required"
                  label="E-Mail"
                  onInput={(e) => setEmail(e.target.value)}
                  hidden
                  onChange={handleEmailChange}
                  error={emailInvalid}
                  helperText={emailInvalid && "Invalid Email!"}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="outlined-required"
                  label="Passwort"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  error={passwordInvalid}
                  helperText={
                    passwordInvalid &&
                    "Das Passwort muss min. 8 Zeichen haben, davon min. eine Nummer und ein Sonderzeichen"
                  }
                />
              </Grid>
              <Grid item xs={12} textAlign={"center"}>
                <Typography variant="caption">
                  Keinen Account? Für einen neuen Account{" "}
                  <a href="/register">anmelden</a>
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign={"center"}>
                <Typography variant="caption">
                  Passwort vergessen?{" "}
                  <a href="/forgotpassword">Passwort zurücksetzen</a>
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleLogin(navigate, setUser, email, password);
                  }}
                >
                  Login
                </Button>
              </Grid>
              <Grid item xs={6} textAlign={"center"}>
                <Button variant="outlined" type="reset">
                  Zurücksetzten
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
      <ErrorModal open={open} setOpen={setOpen} message={message} />
    </>
  );
}

export default Login;
