import { Button, Grid, Paper, Typography } from "@mui/material";
import PayPalButton from "../cmp/PayPalButton";

function Paypal() {
    return (
        <div className="container" style={{ paddingLeft: '250px', paddingRight: '40px', paddingTop: '40px' }}>
            <Paper elevation={3}>
                <Grid container padding={'10px'}>
                    <Grid item xs={12}>
                        <PayPalButton/>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

export default Paypal;