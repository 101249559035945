import * as React from 'react';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from '../axios';
import { DeleteForever } from '@mui/icons-material';
import DeleteModal from './DeleteModal';

  const SipAccountsTable = ({ sipInfo, onRefresh }) => {

  const [publicID, setPublicID] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();

  const handleDelete = (id) => {
    setMessage('Wollen Sie den SIP-Account wirklich löschen?');
    setPublicID(id);
    setOpen(true);
  }

  useEffect(() => {
    if (typeof onRefresh === 'function') {
      onRefresh();
    }
  }, []);

  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><b>Telefonnummer</b></TableCell>
            <TableCell><b>Auth-User</b></TableCell>
            <TableCell><b>SIP-Gateway</b></TableCell>
            <TableCell><b>SIP-Passwort</b></TableCell>
            <TableCell align="center"><b>Löschen?</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sipInfo && sipInfo.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.phonenumber}
              </TableCell>
              <TableCell>{row.sipid}</TableCell>
              <TableCell>{row.gateway}</TableCell>
              <TableCell>{row.passwd}</TableCell>
              <TableCell align="center" onClick={() => handleDelete(row.public_id)}><DeleteForever /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <DeleteModal open={open} setOpen={setOpen} message={message} publicID={publicID} onRefresh={onRefresh} />
    </>
  );
}

export default SipAccountsTable;