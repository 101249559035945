import * as React from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/material';

export default function FAQ() {
    return (
        <>  
            <div style={{ paddingLeft: '250px', paddingRight: '40px', paddingTop: '40px' }}>
                <Typography variant="h4" style={{ paddingBottom: '15px'}}><b>Häufig gestellte Fragen (FAQ)</b></Typography>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <SimpleTreeView>
                        <TreeItem itemId="grid-mos" label="Was ist der MOS-Wert?">
                            <div style={{ padding: '15px' }}>
                                Der MOS-Wert (Mean Opinion Score) ist eine Metrik, die in der Telekommunikationsbranche verwendet wird, um die Qualität von Audio- und Videoübertragungen 
                                zu bewerten. <br />DIAL-ogue verwendet eine Reihe von Algorithmen, um die wahrgenommene Qualität von Audio zu bewerten, und gibt einen MOS-Wert aus, der die 
                                wahrgenommene Qualität repräsentiert. <br />Der MOS-Wert wird auf einer Skala von 1 bis ~4,5 (Speech-Modus) bewertet, wobei 1 die schlechteste Qualität und 4.5x die 
                                beste Qualität darstellt. <br />Im Speech-Modus konzentriert sich DIAL-ogue speziell auf die Bewertung der Qualität von Sprachübertragungen. Es berücksichtigt Faktoren 
                                wie Klangqualität, Verständlichkeit und Hintergrundgeräusche. Ein höherer MOS-Wert deutet auf eine bessere Qualität der Sprachübertragung hin, während ein 
                                niedrigerer Wert auf eine schlechtere Qualität hinweist.
                            </div>
                        </TreeItem>

                        <TreeItem itemId="grid-jitter" label="Was ist Jitter?">
                            <div style={{ padding: '15px' }}>
                                Jitter bezeichnet in der Netzwerktechnik die zeitliche Schwankung bei der Übertragung von Datenpaketen in einem Netzwerk. Es handelt sich dabei um die Variabilität der Paketübertragungszeiten.<br /><br />
                                In einem idealen Netzwerk würden alle Pakete genau zur gleichen Zeit ankommen. In der Realität gibt es jedoch immer eine gewisse Verzögerung, und diese Verzögerung ist nicht immer konstant. Diese Schwankungen in der Verzögerung werden als Jitter bezeichnet.<br /><br />
                                Hoher Jitter kann zu Problemen bei der Datenübertragung führen, insbesondere bei Echtzeitanwendungen wie VoIP (Voice over IP), Video-Streaming oder Online-Gaming, bei denen eine konstante Übertragungsrate wichtig ist. Bei zu hohem Jitter können Audio- und Videoanrufe beispielsweise unregelmäßig und unterbrochen wirken.<br /><br />
                                Jitter kann durch verschiedene Faktoren verursacht werden, darunter Netzwerkkongestion, Timing-Drift oder Routenänderungen.
                            </div>
                        </TreeItem>

                        <TreeItem itemId="grid-latenz" label="Was ist die Latenz?">
                            <div style={{ padding: '15px' }}>
                                Latenz bezeichnet in der Informatik und Netzwerktechnik die Zeit, die ein Datenpaket benötigt, um von der Quelle zum Ziel zu gelangen. Es handelt sich also um die Verzögerung, die auftritt, wenn ein Signal oder Datenpaket über ein Netzwerk gesendet wird.<br />
                                In einem Netzwerk ist die Latenz oft ein kritischer Faktor, da sie die Geschwindigkeit bestimmt, mit der Daten übertragen werden können. Eine hohe Latenz kann zu Verzögerungen bei der Datenübertragung führen, was insbesondere bei Echtzeitanwendungen wie Online-Gaming, Video-Streaming oder VoIP-Anrufen problematisch sein kann.<br />
                                Die Latenz kann durch verschiedene Faktoren beeinflusst werden, darunter die physische Entfernung zwischen Quelle und Ziel, die Qualität der Netzwerkverbindung, die Menge des Netzwerkverkehrs und die Leistungsfähigkeit der Netzwerkhardware.<br />
                                In VoIP-Anwendungen ist eine niedrige Latenz wichtig, um eine klare und verzögerungsfreie Kommunikation zu gewährleisten.
                            </div>
                        </TreeItem>

                        <TreeItem itemId="grid-packetloss" label='Was ist "Packet-Loss"'>
                            <div style={{ padding: '15px' }}>
                                Packet Loss, auf Deutsch Paketverlust, bezeichnet in der Netzwerktechnik das Phänomen, wenn Datenpakete, die über ein Netzwerk gesendet werden, ihr Ziel nicht erreichen.<br />
                                Dies kann verschiedene Gründe haben, wie zum Beispiel Netzwerküberlastung, schlechte Verbindungsqualität, Hardwareprobleme oder Fehler in der Netzwerksoftware.<br />
                                Paketverlust kann die Qualität von Datenübertragungen erheblich beeinträchtigen, insbesondere bei Echtzeitanwendungen wie Video-Streaming, Online-Gaming oder VoIP-Anrufen. Bei hohem Paketverlust können beispielsweise Video- und Audioanrufe abgehackt und unklar werden oder Online-Spiele können laggen.<br />
                                In einigen Netzwerkprotokollen gibt es Mechanismen, um Paketverlust zu erkennen und zu beheben, indem verlorene Pakete erneut gesendet werden. Allerdings kann dies zu zusätzlicher Netzwerklatenz führen.
                            </div>
                        </TreeItem>

                        <TreeItem itemId="grid-registrar" label='Was ist ein Registrar?'>
                            <div style={{ padding: '15px' }}>
                                Ein SIP-Registrar ist ein Server in einem Session Initiation Protocol (SIP) Netzwerk, der die Aufgabe hat, die Standorte von Endgeräten zu verwalten. SIP ist ein Protokoll, das für die Signalisierung und Steuerung von Multimedia-Kommunikationssitzungen in Anwendungen wie VoIP verwendet wird.<br /><br />
                                Wenn ein Endgerät (wie ein VoIP-Telefon oder eine Softphone-Anwendung) eine Verbindung zu einem SIP-Netzwerk herstellt, sendet es eine Registrierungsanforderung an den SIP-Registrar. Diese Anforderung enthält Informationen über die aktuelle IP-Adresse und den Port des Endgeräts.<br /><br />
                                Der SIP-Registrar speichert diese Informationen in einer Datenbank. Wenn ein Anruf für dieses Endgerät eingeht, kann der SIP-Registrar die gespeicherten Informationen verwenden, um den Anruf an das richtige Endgerät weiterzuleiten.<br /><br />
                            </div>
                        </TreeItem>

                        <TreeItem itemId="grid-firewall" label='Welche Ports an der Firewall müssen freigeschaltet sein?'>
                            <div style={{ padding: '15px' }}>
                            </div>
                        </TreeItem>

                        <TreeItem itemId="grid-pbx" label='Was ist eine PBX?'>
                            <div style={{ padding: '15px' }}>
                                Eine PBX (Private Branch Exchange) ist eine private Telefonanlage, die in Unternehmen verwendet wird. Sie ermöglicht die interne Kommunikation zwischen verschiedenen Abteilungen oder Mitarbeitern sowie die externe Kommunikation mit Kunden und Partnern über verschiedene Kommunikationskanäle wie Sprachanrufe, Videoanrufe, Fax usw.<br /><br />
                                Eine PBX bietet Funktionen wie Anrufweiterleitung, Voicemail, Anrufaufzeichnung, interaktive Sprachantwort (IVR) und Konferenzschaltungen. Moderne PBX-Systeme können auch mit VoIP (Voice over IP) Technologie integriert werden, um Anrufe über das Internet zu ermöglichen, was Kosten sparen und die Flexibilität erhöhen kann.
                            </div>
                        </TreeItem>

                        <TreeItem itemId="grid-stun" label='Was ist STUN?'>
                            <div style={{ padding: '15px' }}>
                                STUN (Session Traversal Utilities for NAT) ist ein Netzwerkprotokoll, das von Endgeräten (wie VoIP-Telefonen) verwendet wird, um ihre öffentliche IP-Adresse und Portnummer zu entdecken, wenn sie sich hinter einem NAT (Network Address Translation) Router befinden.<br /><br />
                                In Bezug auf das SIP (Session Initiation Protocol) wird STUN oft verwendet, um Probleme zu lösen, die durch NAT verursacht werden. NAT ist eine Technik, die von Routern verwendet wird, um private IP-Adressen in öffentliche IP-Adressen umzuwandeln und umgekehrt. Dies kann jedoch Probleme verursachen, wenn ein VoIP-Telefon versucht, Anrufe über das Internet zu tätigen oder zu empfangen.<br /><br />
                                STUN hilft dabei, diese Probleme zu lösen, indem es das VoIP-Telefon seine öffentliche IP-Adresse und Portnummer entdecken lässt. Diese Informationen können dann in den SIP- und SDP-Nachrichten verwendet werden, die das Telefon sendet, um Anrufe zu tätigen oder zu empfangen. Dies ermöglicht es dem Telefon, Anrufe über den NAT-Router hinweg korrekt zu routen.
                            </div>
                        </TreeItem>

                        <TreeItem itemId="grid-codec" label='Was ist ein Codec?'>
                            <div style={{ padding: '15px' }}>
                                Ein Audio-Codec ist ein Programm oder eine Gerätesoftware, die zur Codierung und Decodierung von Audiodaten verwendet wird. Der Begriff "Codec" steht für "Coder-Decoder".<br /><br />
                                Die Codierung ist der Prozess der Umwandlung von Audiodaten in ein Format, das für die Übertragung oder Speicherung geeignet ist. Die Decodierung ist der umgekehrte Prozess, bei dem die codierten Audiodaten in ein Format umgewandelt werden, das von Audio-Wiedergabegeräten verstanden wird.<br /><br />
                                Audio-Codecs werden in einer Vielzahl von Anwendungen verwendet, einschließlich Musik-Streaming-Diensten, VoIP-Telefonie und in der Filmindustrie. Sie können entweder verlustbehaftet oder verlustfrei sein. Verlustbehaftete Codecs reduzieren die Größe der Audiodaten erheblich, führen jedoch zu einem Qualitätsverlust. Verlustfreie Codecs behalten die volle Audioqualität bei, führen aber zu größeren Dateien.
                            </div>
                        </TreeItem>
                        <TreeItem itemId="grid-channels" label='Was sind Sprachkanäle?'>
                            <div style={{ padding: '15px' }}>
                                In SIP-Trunks bezieht sich der Begriff "Audiochannels" oder "Sprachkanäle" auf die Anzahl der gleichzeitigen Anrufe, die über den SIP-Trunk geführt werden können. Jeder Sprachkanal ermöglicht einen Anruf. Wenn Sie also beispielsweise 30 Sprachkanäle in Ihrem SIP-Trunk haben, können Sie bis zu 30 gleichzeitige Anrufe tätigen oder empfangen.
                            </div>
                        </TreeItem>
                        <TreeItem itemId="grid-tls" label='Was ist TLS?'>
                            <div style={{ padding: '15px' }}>
                                TLS steht für "Transport Layer Security". Es handelt sich dabei um ein Protokoll, das die Sicherheit von Daten gewährleistet, die über ein Netzwerk, einschließlich des Internets, übertragen werden. TLS bietet Verschlüsselung, Authentifizierung und Integritätsschutz.<br /><br />
                                In Bezug auf SIP (Session Initiation Protocol) wird TLS oft verwendet, um die SIP-Signalisierung zu sichern. Das bedeutet, dass die Informationen, die zur Einrichtung, Steuerung und Beendigung von Anrufen verwendet werden, verschlüsselt werden, um sie vor Abhören und Manipulation zu schützen.<br /><br />
                                Es ist wichtig zu beachten, dass TLS nur die SIP-Signalisierung und nicht den eigentlichen Sprach- oder Videoinhalt des Anrufs schützt. Zur Verschlüsselung der Medieninhalte eines Anrufs wird in der Regel ein anderes Protokoll, wie SRTP (Secure Real-Time Transport Protocol), verwendet.
                            </div>
                        </TreeItem>
                        <TreeItem itemId="grid-srtp" label='Was ist SRTP?'>
                            <div style={{ padding: '15px' }}>
                                SRTP steht für "Secure Real-Time Transport Protocol". Es handelt sich dabei um ein Protokoll, das zur Verschlüsselung von Audio- und Videodaten verwendet wird, die über ein Netzwerk übertragen werden. SRTP bietet Vertraulichkeit, Authentizität und Integritätsschutz für die Medieninhalte eines Anrufs.<br /><br />
                                In VoIP-Anwendungen wird SRTP oft verwendet, um die Sprach- und Videoinhalte von Anrufen zu schützen. Dies ist wichtig, um sicherzustellen, dass vertrauliche Informationen nicht abgehört oder manipuliert werden können.<br /><br />
                                SRTP wird normalerweise in Verbindung mit anderen Protokollen wie SIP (Session Initiation Protocol) und TLS (Transport Layer Security) verwendet, um eine sichere Kommunikation zu gewährleisten.
                            </div>    
                        </TreeItem>
                        <TreeItem itemId="grid-trunk" label='Was ist ein SIP-Trunk?'>
                            <div style={{ padding: '15px' }}>
                                Ein SIP-Trunk ist eine virtuelle Verbindung zwischen einer Telefonanlage (PBX) und einem Internet-Telefonieanbieter, die es ermöglicht, Anrufe über das Internet zu tätigen und zu empfangen. SIP-Trunks ersetzen herkömmliche Telefonleitungen und ermöglichen es Unternehmen, VoIP (Voice over IP) zu nutzen, um Kosten zu sparen und die Flexibilität zu erhöhen.<br /><br />
                                SIP-Trunks können eine beliebige Anzahl von Sprachkanälen (Audiochannels) haben, die die Anzahl der gleichzeitigen Anrufe bestimmen, die über den Trunk geführt werden können. SIP-Trunks können auch zusätzliche Funktionen wie Anrufweiterleitung, Anrufaufzeichnung und Anrufüberwachung bieten.<br /><br />
                                SIP-Trunks sind eine kostengünstige und flexible Lösung für Unternehmen, die VoIP nutzen möchten, da sie es ermöglichen, Anrufe über das Internet zu tätigen und zu empfangen, ohne auf teure Telefonleitungen angewiesen zu sein.
                            </div>
                        </TreeItem>
                    </SimpleTreeView>
                </Paper>
            </div>
        </>
    );
}