import React from "react";
import ErrorModal from "../cmp/ErrorModal"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from '../axios';
import { Grid, Paper, Typography } from "@mui/material";

function MyProfiles() {

    const [profileInfo, setProfileInfo] = React.useState({
        name: "",
        country: "",
        lang: "",
        zip: "",
        city: "",
        street: "",
        housenumber: "",
        tel: "",
        email: "",
        logoimg: "",
        iban: "",
        bic: ""
    });

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState();

    const sendProfileData = () => {

        axios.put("/user/profile", profileInfo)
            .then(response => {
                if (response.data.status === "ok") {
                    setMessage("Benutzer wurde erfolgreich aktualisiert.")
                    setOpen(true)
                }
                else {
                    setMessage("Wrong input")
                    setOpen(true)
                }
            })
            .catch(err => console.log(err));
    };

    React.useEffect(() => {
        axios("/user/")
            .then(response => {
                if (response.data.status === "ok") {
                    const profile = response.data.user.profile
                    setProfileInfo({
                        name: profile.name,
                        country: profile.country,
                        lang: profile.lang,
                        zip: profile.zip,
                        city: profile.city,
                        street: profile.street,
                        housenumber: profile.housenumber,
                        tel: profile.telephone,
                        email: profile.profileEmail,
                        logoimg: profile.logoimg,
                        iban: profile.iban,
                        bic: profile.bic,
                        parentID: profile.parentID
                    })

                }

            })
            .catch(err => console.log(err));
    }, []);

    return (
        <>  
            <div style={{ paddingLeft: '250px', paddingRight: '40px', paddingTop: '40px' }}>
                <Typography variant="h4" style={{ paddingBottom: '15px'}}><b>Mein Profil</b></Typography>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h5">Persönliche Informationen</Typography><br/>
                    <form>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={profileInfo.name}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        name: e.target.value
                                    })}
                                />
                            </Grid>
                            
                            <Grid item xs={3} style={{ paddingLeft: '30px' }}>
                                <TextField
                                    fullWidth
                                    id="country"
                                    label="Land"
                                    value={profileInfo.country}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        country: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs={3} style={{ paddingLeft: '15px'}}>
                                <TextField
                                    fullWidth
                                    id="lang"
                                    label="Sprache"
                                    value={profileInfo.lang}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        lang: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs={5} style={{ paddingTop: '15px'}}>
                                <TextField
                                    fullWidth
                                    id="street"
                                    label="Straße"
                                    value={profileInfo.street}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        street: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs={1} style={{ paddingTop: '15px', paddingLeft: '15px'}}>
                                <TextField
                                    id="housenumber"
                                    label="Hausnummer"
                                    value={profileInfo.housenumber}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        housenumber: e.target.value
                                    })}
                                />
                            </Grid>                            
                            <Grid item xs={6} style={{ paddingTop: '15px', paddingLeft: '30px'}}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="E-Mail"
                                    value={profileInfo.email}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        email: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs={2} style={{ paddingTop: '15px' }}>
                                <TextField
                                    fullWidth
                                    id="zip"
                                    label="Postleitzahl"
                                    value={profileInfo.zip}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        zip: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ paddingTop: '15px', paddingLeft: '15px'}}>
                                <TextField
                                    fullWidth
                                    id="city"
                                    label="Stadt"
                                    value={profileInfo.city}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        city: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs={3} style={{ paddingTop: '15px', paddingLeft: '30px'}}>
                                <TextField
                                    fullWidth
                                    id="tel"
                                    label="Telefon"
                                    value={profileInfo.tel}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        tel: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs={3} style={{ paddingTop: '15px', paddingLeft: '15px'}}>
                                <TextField
                                    fullWidth
                                    id="logoimg"
                                    label="Logo"
                                    value={profileInfo.logoimg}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        logoimg: e.target.value
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
            <div style={{ paddingLeft: '250px', paddingTop: '40px', paddingRight: '40px' }}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h5">Bankverbindung</Typography><br/>
                    <form>
                        <Grid container>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    id="bic"
                                    label="BIC"
                                    value={profileInfo.bic}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        bic: e.target.value
                                    })}
                                />
                            </Grid>
                            
                            <Grid item xs={5} style={{ paddingLeft: '30px' }}>
                                <TextField
                                    fullWidth
                                    id="iban"
                                    label="IBAN"
                                    value={profileInfo.iban}
                                    onChange={e => setProfileInfo({
                                        ...profileInfo,
                                        iban: e.target.value
                                    })}
                                />
                            </Grid>                                                       
                        </Grid>
                    </form>
                </Paper>                
            </div>
            
            <div style={{ paddingLeft: '250px', paddingTop: '40px', paddingRight: '40px' }}>
                <Paper elevation={3} style={{ padding: '20px' }}>                    
                    <Grid container>
                        <Grid item xs={2}>
                            <Button fullWidth variant="outlined" onClick={() => sendProfileData(profileInfo, setOpen, setMessage)}>
                                Speichern
                            </Button>
                        </Grid>                                    
                    </Grid>
                </Paper>                
            </div>            
            <ErrorModal open={open} setOpen={setOpen} message={message} />
        </>
    );
}

export default MyProfiles;