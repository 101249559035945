import { Button, Grid, Paper, Typography } from "@mui/material";

function Home() {

    const handleDownload = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}download`;
    }

    return (
        <div className="container" style={{ paddingLeft: '250px', paddingRight: '40px', paddingTop: '40px' }}>
            <Typography variant="h4" paddingBottom={'15px'}><b>Dashboard</b></Typography>
            <Paper elevation={3}>
                <Grid container padding={'10px'}>
                    <Grid item xs={6}>
                        <Typography variant="h5" color={'#73879C'}><b>Dial-ogue</b></Typography>
                        <Typography>The VOIP Quality Check</Typography>
                    </Grid>
                    <Grid item xs={6} padding={'10px'}>
                        <Typography variant="h5" color={'#73879C'}><b>Dial-ogue Client Download</b></Typography>
                        <Button variant={'outlined'} onClick={handleDownload}>Download Windows Client v1.0.7</Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

export default Home;