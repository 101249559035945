import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalButton = () => {
    return (
        <PayPalScriptProvider options={{ "client-id": "Ad2-TOUCRymrANTQUtiwG2JDN_fddOD_IjO2LP7DYKqrGXBXfibLkEsfXWHo-48_-6TPofkZWPasnzAA","currency": "EUR", "intent": "capture" }}>
            <PayPalButtons
                // style={{ layout: "horizontal" }}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{ amount: { value: "10.00" } }]
                    });
                }}
                onApprove={(data, actions) => {
                    // Capture the funds
                    return actions.order.capture().then((details) => {
                        alert("Payment successful!");
                    });
                }}
            />
        </PayPalScriptProvider>
    );
};

export default PayPalButton;