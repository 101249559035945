import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from '../axios';
import ErrorModal from "../cmp/ErrorModal"
import { Grid, Paper } from "@mui/material";

function SetPassword() {
    const [password, setPassword] = React.useState('');
    const [samepassword, setSamepassword] = React.useState('');
    const [passwordInvalid, setPasswordInvalid] = React.useState(false);
    const [passwordSameInvalid, setPasswordSameInvalid] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState();
    const [tokenValid, setTokenValid] = useState(false);
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    useEffect(() => {
        // Function to run on page load
        const getTokenFromURL = () => {
            if (token) {
                const verifyPayload = {
                    token: token,
                }
                axios.post(`/verifyresetpassword`,verifyPayload)
                    .then(response => {
                        if (response.data.status === "success") {
                            setTokenValid(true);
                            setEmail(response.data.email);
                        } else {
                            setMessage("Token is invalid.");
                            setOpen(true);
                        }
                    })
                    .catch(err => {
                        setMessage(err.response.data.msg);
                        setOpen(true);
                        console.error("Error:", err);
                    });
            }else {
                setMessage("Not authroized to access.");
                setOpen(true);
            }
        };

        getTokenFromURL();
    }, [location.search]);

    const handleForgotPassword = () => {
        const setPasswordPayload = {
            password: password,
            samepassword: samepassword,
            profile: {},
            token: token,
        }

        if (samepassword !== password ) {
            setPasswordSameInvalid(true)
            return
        }
        else
        setPasswordSameInvalid(false)

        if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password)) {
            setPasswordInvalid(true);
            return;
        } else {
            setPasswordInvalid(false);
        }


        axios.post("/setpassword", setPasswordPayload)
            .then(response => {
                console.log(response)
                if (response.data.status === "error") {
                    setMessage(response.data.msg)
                    setOpen(true)
                }
                else {
                    setMessage("Neues Passwort erfolgreich festgelegt. Bitte loggen Sie sich ein")
                    setOpen(true)
                    setTimeout(() => { navigate('/') }, 3000)
                }
            })
            .catch(err => {
                setMessage(err.response.data.msg)
                setOpen(true)
                console.error("Error:", err.response.data);
                setTimeout(() => { navigate('/') }, 3000)
              });
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>                
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <h1>Neu Passwort</h1>
                    {tokenValid && (
                        <div>
                            <p>Email: {email}</p>
                    <form onSubmit={handleForgotPassword}>
                        <Grid container width={400} spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password"
                                    label="Passwort"
                                    onInput={e => setPassword(e.target.value)}
                                    hidden
                                    error={passwordInvalid}
                                    helperText={
                                        passwordInvalid &&
                                        "Password length must be 8 chars with minimum 1 number and 1 special char"
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password_confirm"
                                    label="Passwort wiederholen"
                                    onInput={e => setSamepassword(e.target.value)}
                                    hidden
                                    error={passwordSameInvalid}
                                    helperText={
                                        passwordSameInvalid &&
                                        "Both passwords do not match"
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} textAlign={'center'}>
                                <Button variant="outlined" onClick={() => {                                   
                                    handleForgotPassword(password,samepassword)
                                }}>
                                    Anfrage
                                </Button>                                
                            </Grid>
                            <Grid item xs={6} textAlign={'center'}>               
                                <Button type="reset" variant="outlined" onClick={() => {                                   
                                   setTimeout(() => { navigate('/register') }, 3000)
                                }} >
                                    Registration
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    </div>
                    )}
                    {!tokenValid && (
                        <p>Token is invalid or expired.</p>
                    )}
                </Paper>
            </div>            
            <ErrorModal open={open} setOpen={setOpen} message={message} />
        </>
    );
}

export default SetPassword;