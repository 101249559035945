import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ErrorModal({ open, setOpen, message }) {
  const handleClose = () => {
    setOpen(false)
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6">Info</Typography>
        <Typography variant="body1">{message}</Typography>
        <Button variant="contained" onClick={handleClose}>
          Schließen
        </Button>
      </Box>
    </Modal>
  );
}

export default ErrorModal;