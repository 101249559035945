import React, { useState } from "react";
import { Button, TextField, Grid, Paper, Typography } from "@mui/material";

import axios from '../axios';
import SipAccountsTable from "../cmp/SipAccountsTable";
import ErrorModal from "../cmp/ErrorModal"

function SipAccounts(props) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();
    const [sipInfo, setSipInfo] = useState({
        phonenumber: '',
        sipid: '',
        gateway: '',
        passwd: '',
    });
    const [sipRecords, setSipRecords] = React.useState(null);

    const getSipRecords = () => {
        axios.get("/user/sip")
            .then(response => {
                if (response.data.status === "ok") {
                    setSipRecords(response.data.sip)
                }
                else {
                    setMessage("Could not load data!")
                    setOpen(true)
                }
            })
            .catch(err => console.log(err));
    }

    const sendSipInfoData = () => {

        if (!sipInfo.phonenumber || !sipInfo.sipid || !sipInfo.passwd) {
            // If any required field is empty, display an error message
            setMessage("Please fill in all fields.");
            setOpen(true);
            return;
        }

        axios.post("/user/sip", sipInfo)
            .then(response => {
                if (response.data.status === "ok") {
                    getSipRecords()
                    setMessage("Neuer SIP-Account wurde erfolgreich hinzugefügt.")
                    setOpen(true)
                }
                else {
                    setMessage(response.data.msg)
                    setOpen(true)
                }
        })
        .catch(err => {
            setMessage(err.response.data.msg)
            setOpen(true)
            console.log(err)
        });
    }

    const handlePhoneNumberChange = (e) => {
        let enteredPhoneNumber = e.target.value;
        // Remove any non-numeric characters
        enteredPhoneNumber = enteredPhoneNumber.replace(/\D/g, '');
        // Ensure the phone number starts with "00"
        if (!enteredPhoneNumber.startsWith('00')) {
            enteredPhoneNumber = '00' + enteredPhoneNumber;
        }

        // Update the state
        setSipInfo({
            ...sipInfo,
            phonenumber: enteredPhoneNumber
        });
    };

    return (
        <>
            <div style={{ paddingLeft: '225px', paddingTop: '40px', paddingRight: '40px' }}>
                <Typography variant="h4" style={{ paddingBottom: '15px' }}><b>SIP Accounts</b></Typography>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Grid container>                
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                id="phonenumber"
                                label="Telefonnummer"
                                required
                                value={sipInfo.phonenumber}
                                onChange={handlePhoneNumberChange}
                            />
                        </Grid>
                        <Grid item xs={7}></Grid>
                        <Grid item xs={4} style={{ paddingTop: '15px', paddingRight: '15px' }}>
                            <TextField
                                fullWidth
                                id="sipid"
                                label="SIP Auth-User"
                                required
                                value={sipInfo.sipid}
                                onChange={e => setSipInfo({
                                    ...sipInfo,
                                    sipid: e.target.value
                                })}
                            />
                        </Grid> 
                        <Grid item xs={4} style={{ paddingTop: '15px', paddingRight: '15px' }}>
                            <TextField
                                fullWidth
                                type="password"
                                id="passwd"
                                label="SIP-Passwort"
                                required
                                value={sipInfo.passwd}
                                onChange={e => setSipInfo({
                                    ...sipInfo,
                                    passwd: e.target.value
                                })}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ paddingTop: '15px' }}>
                            <TextField
                                fullWidth
                                id="gateway"
                                label="SIP-Gateway"
                                value={sipInfo.gateway}
                                onChange={e => setSipInfo({
                                    ...sipInfo,
                                    gateway: e.target.value
                                })}
                            />
                        </Grid> 
                        <Grid item xs={2} style={{ paddingTop: '15px'}}>
                            <Button fullWidth variant="outlined" onClick={() => sendSipInfoData(sipInfo, setOpen, setMessage)}>
                                Speichern
                            </Button>
                        </Grid>                                                                                                                                     
                    </Grid>
                </Paper>
            </div>

        <Grid container style={{ paddingLeft: '225px', paddingTop: '15px', paddingRight: '40px' }}>
                <SipAccountsTable sipInfo={sipRecords} onRefresh={getSipRecords} />
        </Grid>
       
        <ErrorModal open={open} setOpen={setOpen} message={message} />
    </>
);
}

export default SipAccounts;