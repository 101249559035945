import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const FilterSelect = ({ label, values, selectedValue, handleChange, name }) => (
  <FormControl sx={{ m: 1, minWidth: 120 }}>
    <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
    <Select
      labelId={`${name}-select-label`}
      id={`${name}-select`}
      name={name}
      value={selectedValue}
      label={label}
      onChange={handleChange}
    >
      {Object.entries(values).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default FilterSelect;
