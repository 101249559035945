import React, { useEffect, useState } from 'react';
import { Card, CardActions, CardContent, Button, Typography, Grid, TextField, 
        Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';

import axios from '../axios';
import ErrorModal from '../cmp/ErrorModal';
import { Link } from 'react-router-dom';

function Clients() {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [user, setUser] = useState(null);
    const [childs, setChilds] = useState([]);

    const handleInvite = () => {
        if (!email) {
            setMessage("Please enter Email");
            setOpen(true);
            return;
        }
        axios.post("/user/invite", { email: email })
            .then(response => {
                if (response.data.status === "ok") {
                    setMessage('Eine Einladug wurde an die E-Mail Adresse gesedet.');
                    setOpen(true);
                }
                else {
                    setMessage("Daten konnten nicht geldaden werden!");
                    setOpen(true);
                }
            })
            .catch(err => console.log(err));
    }

    const handleRemove = (id) => {
        console.log('ID', id)
    }

    const handleEmailChange = (e) => {
        const enteredEmail = e.target.value;
        setEmail(enteredEmail);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(enteredEmail);
        setEmailError(!isValidEmail);
    };

    useEffect(() => {
        const fetchData = () => {
            axios.get("/user")
                .then(response => {
                    if (response.data.status === "ok") {
                        setUser(response.data.user);
                    }
                    else {
                        setMessage("Daten konnten nicht geldaden werden!");
                        setOpen(true);
                    }
                })
                .catch(err => console.log(err))
            
            axios.get("/user/childs")
                .then(response => {
                    if (response.data.status === "ok") {
                        setChilds(response.data.childs);
                    }
                    else {
                        setMessage("Child-Daten konnten nicht geldaden werden!");
                        setOpen(true);
                    }
                })
                .catch(err => console.log(err));
        }

        if (user === null) {  
            fetchData();
            return
        }
    }, [user, childs]);

    return (
        <>  
            <Typography variant="h4" style={{ paddingBottom: '15px', paddingLeft: '250px', paddingTop: '40px'}}><b>Mein Team</b></Typography>
            <Grid container style={{ paddingLeft: '250px' }}>
                <Grid item xs={4} style={{ paddingRight: '15px' }}>
                    <Card xs={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ paddingBottom: '15px' }}>
                                Benutzer in Dein Team einladen:
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} style={{ paddingBottom: '15px' }}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="E-Mail Adresse"
                                        onChange={handleEmailChange}
                                        error={emailError}
                                        helperText={emailError && "Invalid Email!"}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <Button fullWidth variant="outlined" onClick={handleInvite}>
                                        Einladen
                                    </Button>
                                </Grid>
                                <Grid item xs={4}></Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Mehr erfahren</Button>
                        </CardActions>
                    </Card>
                </Grid>
                { user ? <Grid item xs={4}>
                    <Card xs={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ paddingBottom: '15px' }}>
                                Dein Operator:
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} style={{ paddingBottom: '15px' }}>
                                    <Typography variant="h5" component="div" style={{ paddingBottom: '15px' }}>
                                        { user.parent.email }
                                    </Typography>
                                    <Typography variant="p" component="div" style={{ paddingBottom: '15px' }}>
                                        { user.parent.name? user.parent.name : '' }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        
                    </Card>
                </Grid>
                :
                <Grid></Grid>}
                { childs.length > 0 ? 
                <Grid item xs={11} style={{ paddingTop: '30px' }}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ paddingBottom: '15px' }}>
                                Meine Benutzer:
                            </Typography>
                            <Grid container>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>E-Mail</TableCell>
                                            <TableCell align="center" width={'10%'}>Anrufe</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { childs.map((child, i) => (
                                            <TableRow 
                                                key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell><Link to={'mailto:' + child.email }>{ child.email }</Link></TableCell>
                                                <TableCell align="center">{ child.callCount }</TableCell>                                                
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>     
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                : <Grid></Grid>
                }
            </Grid>
            <ErrorModal open={open} setOpen={setOpen} message={message} />
        </>
    );
}

export default Clients;